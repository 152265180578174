<ng-container *ngIf="isAdmin">

<h1>
	<span routerLink="/schools" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">School toevoegen</span>
	<span *ngIf="item.id">School wijzigen</span>
</h1>


<div class="alert-message-container">
	<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
	<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>
</div>

<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

<section class="tab-buttons mt-4">
	<nav class="nav nav-pills nav-justified">
	  <span class="btn btn-primary mr-1"  (click)="changeTab('info')" [ngClass]="{'active' : tabs['info'] == true}">Algemeen</span>
	  <span class="btn btn-primary mr-1" (click)="changeTab('licenties')" [ngClass]="{'active' : tabs['licenties'] == true}">Licenties</span>
	  <span class="btn btn-primary mr-1" (click)="changeTab('teachers')" [ngClass]="{'active' : tabs['teachers'] == true}">Docenten</span>
	  <span class="btn btn-primary mr-1" (click)="changeTab('students')" [ngClass]="{'active' : tabs['students'] == true}">Leerlingen</span>
	</nav>
</section>

<section class="school-tab-container mb-5 mt-5 ">
	<section class="school-info" *ngIf="tabs['info'] == true">
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Actief:</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="active" name="active" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
				  <label class="custom-control-label" for="active"></label>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Naam:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="name" required [(ngModel)]="item.name" name="name">
				<div class="error" *ngIf="errors.name">{{errors.name}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Locatie:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="location" [(ngModel)]="item.location" name="location">
		   		<div class="error" *ngIf="errors.location">{{errors.location}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Plaats:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="city" required [(ngModel)]="item.city" name="city">
		   		<div class="error" *ngIf="errors.city">{{errors.city}}</div>
			</div>
		</div>
		
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Niveau wijzigen actief:</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="canSwitchStudyLevel" name="canSwitchStudyLevel" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.canSwitchStudyLevel">
				  <label class="custom-control-label" for="canSwitchStudyLevel"></label>
				</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Regio:</label>
			</div>
			<div class="col-md-10">
				<select name="region" *ngIf="item" class="form-control" id="region" [(ngModel)]="item.region" name="region">
					<option *ngFor="let regionEnum of item.regionEnums" value="{{ regionEnum }}" [selected]="regionEnum == item.region">{{ regionEnum }}</option>
				</select>
		   		<div class="error" *ngIf="errors.region">{{errors.region}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Bevoegd gezag-nummer:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="authorityNumber" required [(ngModel)]="item.authorityNumber" name="authorityNumber">
				<div class="error" *ngIf="errors.authorityNumber">{{errors.authorityNumber}}</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Brin-nummer:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="brinNumber" required [(ngModel)]="item.brinNumber" name="brinNumber">
				<div class="error" *ngIf="errors.brinNumber">{{errors.brinNumber}}</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Vestigingsnummer:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="departmentNumber" required [(ngModel)]="item.departmentNumber" name="departmentNumber">
				<div class="error" *ngIf="errors.departmentNumber">{{errors.departmentNumber}}</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Verwijder na (dagen):</label>
			</div>
			<div class="col-md-10">
				<select name="deleteAfterMonths" *ngIf="item" class="form-control" id="deleteAfterMonths" [(ngModel)]="item.deleteAfterMonths" name="deleteAfterMonths">
					<option *ngFor="let option of deleteOptions" value="{{ option['value'] }}" [selected]="option == item.deleteAfterMonths">{{ option['name'] }}</option>
				</select>
				<div class="error" *ngIf="errors.deleteAfterMonths">{{errors.deleteAfterMonths}}</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Voorpagina:</label>
			</div>
			<div class="col-md-10">
				<select name="region" *ngIf="item" class="form-control" id="frontType" [(ngModel)]="item.frontType" name="frontType">
					<option *ngFor="let frontType of frontTypes" value="{{ frontType['key'] }}" [selected]="frontType['key'] == item.frontType">{{ frontType['value'] }}</option>
				</select>
		   		<div class="error" *ngIf="errors.region">{{errors.frontType}}</div>
			</div>
		</div>

		<div class="form-group row" *ngIf="item['frontType'] == 'CHAPTER'">
			<div class="col-md-2">
				<label class="col-form-label">Voorpagina hoofdstuk:</label>
			</div>
			<div class="col-md-10">
				<select name="region" *ngIf="item" class="form-control" id="frontTypeChapterId" [(ngModel)]="item.frontTypeChapterId" name="frontTypeChapterId">
					<option *ngFor="let chapter of chapters" value="{{ chapter['id'] }}" [selected]="chapter['id'] == item.frontTypeChapterId">{{ chapter['name'] }}</option>
				</select>
		   		<div class="error" *ngIf="errors.region">{{errors.frontTypeChapterId}}</div>
			</div>
		</div>

		<!-- div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Extern id:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="externalId" required [(ngModel)]="item.externalId" name="externalId">
				<div class="error" *ngIf="errors.externalId">{{errors.externalId}}</div>
			</div>
		</div -->

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Verwerkersovereenkomst aanwezig?</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="gdprAccepted" name="gdprAccepted" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.gdprAccepted">
				  <label class="custom-control-label" for="gdprAccepted"></label>
				</div>
			</div>
		</div>

		<div class="form-group row d-none">
			<div class="col-md-2">
				<label class="col-form-label">Proeflicentie organisatie</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="demoLicense" name="demoLicense" [ngModelOptions]="{standalone: true}" [(ngModel)]="item['demoLicense']">
				  <label class="custom-control-label" for="demoLicense"></label>
				</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Heeft PDF-weergave?</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="pdfLicense" name="pdfLicense" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.pdfLicense">
				  <label class="custom-control-label" for="pdfLicense"></label>
				</div>
			</div>
		</div>
		
		<!-- <div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Heeft keuzeopdrachten?</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="choiceAssignmentLicense" name="choiceAssignmentLicense" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.choiceAssignmentLicense">
				  <label class="custom-control-label" for="choiceAssignmentLicense"></label>
				</div>
			</div>
		</div> -->

		<div class="form-group row mt-4">
			<div class="col-md-8">
				<button (click)="save()" class="btn btn-primary">
					<span *ngIf="!item.id">Toevoegen</span>
					<span *ngIf="item.id">Opslaan</span>
				</button>
			</div>
		</div>
	</section>
	<section class="school-licenties" *ngIf="tabs['licenties'] == true">

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Account aanhouden:</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="extendIntention" name="extendIntention" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.extendIntention">
				  <label class="custom-control-label" for="extendIntention"></label>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">E-mailadres contactpersoon:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="extendContactEmail" placeholder="leermiddelen@mijnschool.nl" required [(ngModel)]="item.extendContactEmail" name="extendContactEmail">
				<div class="error" *ngIf="errors.extendContactEmail">{{errors.extendContactEmail}}</div>
			</div>
		</div>
		
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Licenties:</label>
			</div>
			<div class="col-md-10">
				<section *ngFor="let section of sections" class="license-container">
					<h4>{{section['name']}}</h4>
					<div class="license-chapter-container">
						<div class="row pt-1 pb-1" *ngFor="let chapter of section['chapters']">
							<div class="col-12">
								<input type="number" name="chapter[{{chapter['id']}}]"  [ngModel]="getLicenseCount(chapter['id'])" (change)="updateLicenseCount($event, chapter['id'])" class="license-count float-left mr-3" id="chapter_{{chapter['id']}}"/>
								{{chapter['name']}}
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
		<div class="form-group row mt-4">
			<div class="col-md-8">
				<button (click)="save()" class="btn btn-primary">
					<span *ngIf="!item.id">Toevoegen</span>
					<span *ngIf="item.id">Opslaan</span>
				</button>
			</div>
		</div>
	</section>
	<section class="school-teachers" *ngIf="tabs['teachers'] == true">
		<div class="table-search">
			<input 
				[(ngModel)]="teacherSearchKeyword" 
				(keyup)="search($event, teachersOriginal)">
		</div>
		<table class="table">
		  <thead class="thead-dark">
		    <tr>
			  <th width="10">#</th>
		      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['email']['direction'] == 'asc', 'sort-desc': sortColumns['email']['direction'] == 'desc'}" (click)="sort('email', teachers)">Docent</th>
		      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['regDate']['direction'] == 'asc', 'sort-desc': sortColumns['regDate']['direction'] == 'desc'}" (click)="sort('regDate', teachers)">Registratiedatum</th>
		      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['lastLogin']['direction'] == 'asc', 'sort-desc': sortColumns['lastLogin']['direction'] == 'desc'}" (click)="sort('lastLogin', teachers)">Laatst ingelogd</th>
		      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}" (click)="sort('active', teachers)" width="100">Status</th>
		      <th scope="col" width="100">Acties</th>
		    </tr>
		  </thead>
		  <tbody>
		    <tr *ngFor="let user of teachers let i = index" [ngClass]="{ 'd-none': user.hidden == true }">
				<td>{{i + 1}}</td>
				<td><a routerLink="/user/form/{{user.id}}">{{user.email}}</a></td>
				<td>
					{{user.regDate}}
				</td>
				<td>
					{{user.lastLogin}}
				</td>
				<td>
					<div *ngIf="user.active==1">Actief</div>
					<div *ngIf="user.active==0">Niet actief</div>
				</td>
			    <td>
			    	<a routerLink="/user/form/{{user.id}}"><i class="fas fa-edit"></i></a> &nbsp;
			    	<span class="delete-link" (click)="confirmDelete(user)"><i class="fas fa-trash"></i></span>
			    </td>
		    </tr>
		  </tbody>
		</table>
	</section>
	<section class="school-students" *ngIf="tabs['students'] == true">
		<div class="row">
			<div class="col-md-2">
				<div style="padding:15px;border:1px solid #333333;">
					<h3 style="margin:0px;padding:0;padding-bottom: 15px;font-size:18px;font-weight:bold;">Klassen</h3>
					<div *ngFor="let classroom of classroomList">
						<input 
							type="checkbox" 
							id="classroom{{classroom.name}}"
							name="classrooms[]" 
							[value]="classroom.name" 
							(change)="search($event, studentsOriginal)" 
							[(ngModel)]="classroom.checked"> 
						<label for="classroom{{classroom.name}}" style="cursor:pointer;padding-left:5px;display:inline-block;">
							{{ classroom.name }} 
						</label>
					</div>
				</div>
			</div>
			<div class="col-md-10">
				<div class="table-search">
					<input 
						[(ngModel)]="studentSearchKeyword" 					
						(keyup)="search($event, studentsOriginal)" 
						style="margin-top:0;">
				</div>
				<table class="table">
					<thead class="thead-dark">
						<tr>
						<th width="10">#</th>
						<th width="10px">Leerling</th>
						<th>Klas</th>
						<th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['regDate']['direction'] == 'asc', 'sort-desc': sortColumns['regDate']['direction'] == 'desc'}" (click)="sort('regDate', students)">Registratiedatum</th>
						<th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['lastLogin']['direction'] == 'asc', 'sort-desc': sortColumns['lastLogin']['direction'] == 'desc'}" (click)="sort('lastLogin', students)">Laatst ingelogd</th>
						<th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}" (click)="sort('active', students)" width="100">Status</th>
						<th scope="col" width="120">Acties</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let user of students let i = index" [ngClass]="{ 'd-none': user.hidden == true }">
							<td>{{i + 1}}</td>
							<td><a routerLink="/user/form/{{user.id}}">{{user.email}}</a></td>
							<td>
								<ng-container *ngFor="let classroom of user.classrooms; let i = index">{{i > 0 ? ', ' : ''}}<a routerLink="/user/form/{{classroom}}">{{user.classroomTexts[i]}}</a></ng-container>
							</td>
							<td>
								{{user.regDate}}
							</td>
							<td>
								{{user.lastLogin}}
							</td>
							<td>
								<div *ngIf="user.active==1">Actief</div>
								<div *ngIf="user.active==0">Niet actief</div>
							</td>
							<td>
								<a routerLink="/user/form/{{user.id}}" class="edit-link"><i class="fas fa-edit"></i></a> &nbsp;
								<span class="delete-link" (click)="confirmDelete(user)"><i class="fas fa-trash"></i></span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section>
</section>
</ng-container>
<ng-container *ngIf="!isAdmin">
	<p>Je hebt geen toegang tot deze pagina.</p>
</ng-container>