<ng-container *ngIf="isAdmin">
<h1>
	Gebruikers
</h1>

<div class="table-search mb-1">
	<input (keyup)="search($event, usersOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
	  <th width="10">#</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('name', users)">Gebruiker</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['organisationName']['direction'] == 'asc', 'sort-desc': sortColumns['organisationName']['direction'] == 'desc'}"  (click)="sort('organisationName',users)">School</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['regDate']['direction'] == 'asc', 'sort-desc': sortColumns['regDate']['direction'] == 'desc'}"  (click)="sort('regDate',users)">Registratiedatum</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['lastLogin']['direction'] == 'asc', 'sort-desc': sortColumns['lastLogin']['direction'] == 'desc'}"  (click)="sort('lastLogin',users)">Laatst ingelogd</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['roleText']['direction'] == 'asc', 'sort-desc': sortColumns['roleText']['direction'] == 'desc'}"  (click)="sort('roleText',users)">Rol</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}"  (click)="sort('active',users)" width="100">Status</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users | paginate: paginationConfig; let i = index" [ngClass]="{ 'd-none': user.hidden == true }">
		<td>{{ users.indexOf(user) + 1 }}</td>
		<td><a routerLink="/user/form/{{user.id}}">{{user.email}}</a></td>
		<td>
			{{user.organisationName}} <span *ngIf="user.organisationLocation">({{user.organisationLocation}})</span>
		</td>
		<td>
			{{user.regDate}}
		</td>
		<td>
			{{user.lastLogin}}
		</td>
		<td>
			{{user.roleText}}
		</td>
		<td>
			<div *ngIf="user.active==1">Actief</div>
			<div *ngIf="user.active==0">Niet actief</div>
		</td>
		<td>
			<a routerLink="/user/form/{{user.id}}"><i class="fas fa-edit"></i></a>  &nbsp;
			<span class="delete-link" (click)="confirmDelete(user)"><i class="fas fa-trash"></i></span>
		</td>
    </tr>
  </tbody>
</table>


<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
</ng-container>
<ng-container *ngIf="!isAdmin">
	<p>Je hebt geen toegang tot deze pagina.</p>
</ng-container>